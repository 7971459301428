import { Value } from "@stringtale/react"
import { useQuery } from "@blitzjs/rpc"
import styled from "@emotion/styled"
import * as Dialog from "@radix-ui/react-dialog"
import Image from "next/image"
import { ReactNode, useEffect, useMemo } from "react"
import { DARK_BLUE_COLOR, LIGHT_GREEN_COLOR, ORANGE_COLOR } from "theme/colors"
import { PX14, PX16, PX22 } from "theme/sizes"
import Button from "ui/Button"
import Container from "ui/Container"
import Group from "ui/Group"
import Stack from "ui/Stack"
import LogoAsset from "../assets/123ZINGkids_logow.svg"
import getCurrentKid from "../queries/getCurrentKid"
import { Routes } from "@blitzjs/next"
import { css } from "@emotion/react"
import { FaArrowLeft } from "@react-icons/all-files/fa/FaArrowLeft"
import useHashParam from "app/core/hooks/useHashParam"
import { useIsKidsPreview } from "app/core/hooks/useIsKids"
import useLinks from "app/core/hooks/useLinks"
import Layout from "app/core/layouts/Layout"
import { addQueryParams } from "app/core/utils/addQueryParams"
import * as KidsMessageAlert from "app/kids/components/KidsMessageAlert"
import { MODAL_QUERY_KEY, MODAL_QUERY_KIDS_TYPE_VALUE } from "app/playlists/consts"
import getCurrentGroup from "app/users/queries/getCurrentGroup"
import Link from "next/link"
import { PiVinylRecordBold } from "@react-icons/all-files/pi/PiVinylRecordBold"
import { TbHome } from "@react-icons/all-files/tb/TbHome"
import { TbMusic } from "@react-icons/all-files/tb/TbMusic"
import Center from "ui/Center"
import Text from "ui/Text"
import KidProfileModal from "./KidProfileModal"
import KidsMessageModal from "./KidsMessageModal"
import { RoundedButton } from "./RoundedButton"

const Root = styled(Stack)`
  gap: 60px;
  padding-bottom: 20px;
`

const Header = styled(Container)`
  height: 100px;
  background: ${DARK_BLUE_COLOR};
  border-radius: 25px;
  box-shadow: 0px 2px 20px #0000004d;
  margin-top: 37px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;

  img {
    margin-top: -40px;
    margin-left: -30px;
  }
`

const User = styled(Button)`
  background-color: ${ORANGE_COLOR};
  border-radius: 16px;
  color: white;
  box-sizing: border-box;
  font-size: ${PX22};
  font-weight: 600;
  height: 100%;
  padding: 0 24px;
  display: grid;
  place-items: center;
`

const MenuButton = styled(Button) <{ isActive?: boolean }>`
  display: flex;
  flex-direction: column;
  color: white;
  font-size: ${PX22};
  padding: 12px 40px;
  border-radius: 24px;
  border: 3px solid transparent;
  ${(p) =>
    p.isActive &&
    css`
      border: 3px solid white;
      background: #ffffff80 0% 0% no-repeat padding-box;
    `}
`

const BackButton = styled(Button)`
  display: inline-flex;
  gap: 8px;
  font-size: ${PX14};
`

const PreviewBarRoot = styled(Container.Root)`
  background-color: #e22a4a;
`

const PreviewBarContent = styled(Container.Content)`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  color: white;
  height: 64px;
`

const PreviewBar = () => {
  const [currentGroup] = useQuery(getCurrentGroup, undefined)
  return (
    <PreviewBarRoot>
      <PreviewBarContent>
        <div>
          <BackButton
            as={Link}
            {...addQueryParams(Routes.Home(), {
              [MODAL_QUERY_KEY]: MODAL_QUERY_KIDS_TYPE_VALUE,
            })}
          >
            <FaArrowLeft size="18px" />{" "}
            <Value name="apps.web.src.kids.components.kidslayout.terug_naar_de_kidslijst">
              Terug naar de Kidslijst
            </Value>
          </BackButton>
        </div>
        <Text size={PX16} bold>
          <Value
            format={{
              groep: currentGroup?.displayTitle,
            }}
            name="apps.web.src.kids.components.kidslayout.kidspagina_preview"
          >
            {`Kidspagina preview: {groep}`}
          </Value>
        </Text>
      </PreviewBarContent>
    </PreviewBarRoot>
  )
}

export const KIDS_MESSAGE_MODAL_KEY = "kids_bericht_modal"

export default function KidsLayout({
  children,
  type,
}: {
  children: ReactNode
  type?: "home" | "lesson" | "studio"
}) {
  const [kid] = useQuery(getCurrentKid, undefined)
  const getLink = useLinks()
  const isPreview = useIsKidsPreview()
  const unread = useMemo(() => {
    return (
      kid?.saves?.filter((save) => save.response?.isRead === false)?.length || 0
    )
  }, [kid])
  const [isMessageModalOpen, setIsMessageModalOpen] = useHashParam(
    KIDS_MESSAGE_MODAL_KEY,
    "boolean"
  )
  useEffect(() => {
    const hasSeenUnread = window.sessionStorage.getItem(
      "hasSeenUnreadKidsMessages"
    )
    if (hasSeenUnread !== "true") {
      if (unread > 0) {
        setIsMessageModalOpen(true)
      } else {
        window.sessionStorage.setItem("hasSeenUnreadKidsMessages", "true")
      }
    }
  }, [setIsMessageModalOpen, unread])

  return (
    <Layout title="123ZING Kids">
      <style jsx global>{`
        body {
          background-color: ${LIGHT_GREEN_COLOR};
        }
      `}</style>
      {isPreview ? <PreviewBar /> : null}
      <Root>
        <Header>
          <Image src={LogoAsset} width={158} height={93} alt="Logo" />
          <Group>
            <MenuButton
              as={Link}
              {...getLink(Routes.KidsPage())}
              isActive={type === "home"}
            >
              <TbHome size={30} />
              <Value name="apps.web.src.kids.components.kidslayout.home">
                Home
              </Value>
            </MenuButton>
            <MenuButton
              as={Link}
              {...getLink(Routes.KidsLessonPage())}
              isActive={type === "lesson"}
            >
              <TbMusic size={30} />
              <Value name="apps.web.src.kids.components.kidslayout.lessen">
                Lessen
              </Value>
            </MenuButton>
            <MenuButton
              as={Link}
              {...getLink(Routes.KidsStudioPage())}
              isActive={type === "studio"}
            >
              <PiVinylRecordBold size={30} />
              <Value name="apps.web.src.kids.components.kidslayout.studio">
                Studio
              </Value>
            </MenuButton>
          </Group>
          <Dialog.Root>
            <Dialog.Trigger asChild>
              <User>{kid?.firstName}</User>
            </Dialog.Trigger>
            <KidProfileModal kid={kid} />
          </Dialog.Root>
        </Header>
        {children}

        <Center>
          <Group gap="12px">
            <RoundedButton
              as="a"
              // @ts-ignore
              href="https://www.123zing.nl/kind-ouder-verzorger/"
              target="_blank"
            >
              <Value name="apps.web.src.kids.components.kidslayout.uitleg_voor_ouders_en_verzorgers">
                Uitleg voor ouders en verzorgers
              </Value>
            </RoundedButton>
            <RoundedButton
              as="a"
              // @ts-ignore
              href="https://123zing.nl/privacyverklaring/"
              target="_blank"
            >
              <Value name="apps.web.src.kids.components.kidslayout.privacy">
                Privacy
              </Value>
            </RoundedButton>
          </Group>
        </Center>
      </Root>

      {(!!kid?.saves?.length) ? (
        <KidsMessageAlert.Root onClick={() => setIsMessageModalOpen(true)}>
          {unread > 0 ? (
            <KidsMessageAlert.Amount>{unread}</KidsMessageAlert.Amount>
          ) : null}
        </KidsMessageAlert.Root>
      ) : null}

      <Dialog.Root
        open={isMessageModalOpen}
        onOpenChange={(val) => {
          setIsMessageModalOpen(val)
          if (!val) {
            window.sessionStorage.setItem("hasSeenUnreadKidsMessages", "true")
          }
        }}
      >
        <KidsMessageModal kid={kid} />
      </Dialog.Root>
    </Layout>
  )
}
